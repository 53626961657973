<template>
	<div class="productContainer">
		<div class="banner"></div>
		<div class="content_container">
			<div class="font1">电力物联网</div>
			<div class="font2"><span class="font2-text">GRID PRODUCTS</span><span class="splitLine"></span></div>
			<div class="infoContainer">
				<div class="leftPad">
					<ul>
						<li v-for="item in articalList" :active="item.active?'true':'false'" :key="item.articalId" @click="viewItem(item)">{{item.articalName}}</li>
					</ul>
				</div>
				<div class="centerContent" v-html="currentContent"></div>
				<div style="clear: both;"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getArticles
	} from '@/api/request'
	import {
		Base64
	} from 'js-base64'
	export default {
		name: 'product',
		props: ['id'],
		data() {
			return {
				articalList: [],
				currentContent: "",
				paramId:''
			}
		},
		computed: {

		},
		mounted() {
			this.paramId = this.$route.params.id;
		},
		created() {
			getArticles({
				currentPage: 1,
				pageSize: 50,
				topicCode: "product",
				sortName:"A_SUMMARY",
				sortString:"asc"
				
			}).then(resp => {
				let _this = this;
				if (resp.data.result.data) {
					resp.data.result.data.forEach(function(item, index) {
						_this.articalList.push({
							articalId: item.id,
							active: index == 0,
							articalName: item.aTitle,
							articleCotnent: item.aContent
						});
						if (index == 0) {
							_this.currentContent = Base64.decode(item.aContent);
						}

					});
					let paramId = this.paramId;
					if(paramId){
						this.articalList.forEach(function(item){
							item.active = item.articalId==paramId;
							if(item.active){
								_this.currentContent = Base64.decode(item.articleCotnent);
							}
						});
					}
				}
			})
		},
		methods: {
			viewItem(inputItem) {
				let _this = this;
				this.articalList.forEach(function(item) {
					if (inputItem.articalId == item.articalId) {
						item.active = true;
						_this.currentContent = Base64.decode(item.articleCotnent);

					} else {
						item.active = false;
					}
				});
			}
		}
	}
</script>

<style>
	.banner {
		width: 100%;
		height: 10rem;
		background: url(../../public/images/introductionbanner.png) no-repeat;
		background-size: 100% 100%;
	}

	.productContainer {
		min-height: 800px;
		background-color: #175068;

	}
</style>
